body{
  width: 100%;
  margin: auto;
  font-family: 'Gothic A1', sans-serif;
}

.rdrDay {
    font-family: 'Gothic A1', sans-serif; 
    font-weight: 700
}

.Post{
  margin:12px auto;
  padding: 12px 20px;
  border:1px solid #eee;
  border-radius: 4px;
}

.PostImage{
  max-width: 250px
}


a{
	text-decoration: none;
}

a.active{
	text-decoration: underline;
}

/*.dark{
	background: white;
}*/

.light{
	background: white;
}

.brief3{
  background-color: #1FBACA;
}

.sectWidth{
  width: 1200px;
}

.menuItem{
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  display: flex;
  align-items: center;
}

.tog{
  height: 16.333333015441895px;
width: 26.666667938232422px;
margin-left: 4px !important;
}

.togBtn{
  height: 14.333333015441895px;
width: 14.333333015441895px;
left: 2.66650390625px;
top: 9.333343505859375px;
background-color: white;
margin-top: -1px;
}

.vector{
  height: 27px;
width: 26.99869155883789px;
left: 0px;
top: 0px;
border-radius: 0px;

}

.logoBox{
  height: 56px;
width: 86px;
left: 120px;
top: 30px;
border-radius: 0px;

}


.authBtn{
  height: 32px;
  width: 106px;
  left: 1214px;
  top: 42px;
  border-radius: 50px;
  background: #DE7634;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

}

.imgBx{
  height: 455px;
width: 371px;
/*left: 120px;
top: 165px;*/

}

.imgBx1{
  height: 440px;
width: 440px;
/*left: 120px;
top: 165px;*/

}

.imgBx2{
  height: 242px;
width: 157px;
/*left: 120px;
top: 165px;*/

}

h2{
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: 72px;
letter-spacing: 0em;
}

h1{

font-size: 60px;
font-weight: 700;
line-height: 96px;
letter-spacing: 0em;
text-align: left;
}

.coin{
height: 45px;
width: 45px;
border-radius: 0px;
}


h3{
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.h3Bold{

font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 38px;
letter-spacing: 0em;

}

.gen{
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 26px;
  letter-spacing: 0em;
}

.genBold{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}


.h3Dark{
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;

}

.h3Dark24{
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;

}

.uniqueBox{
  height: 38px;
  width: 359px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
}

.blueBg1{
  background: #5346DC;
}

.blueBg2{
  background: #3F31D8;
}

.blueBg3{
  background: #3426C9;
}

.blueBg4{
  background: #675CE0;
}


.outlineText{

  font-size: 120px;
  font-weight: 700;
  line-height: 192px;
  letter-spacing: 0em;
  -webkit-text-stroke: 3px #DE7634;
  -webkit-text-fill-color: transparent;
}

.outlineText1{
  font-size: 250px;
  font-weight: 700;
  line-height: 401px;
  letter-spacing: 0em;
  -webkit-text-stroke: 1px #262626;
  -webkit-text-fill-color: white;
}

.outlineText1Drk{
  font-size: 250px;
  font-weight: 700;
  line-height: 401px;
  letter-spacing: 0em;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: #1A1A1A;

}

.rotateArrow{
  transform: rotate(-5deg);
}


.uniqueBoxDrk{
  height: 38px;
  width: 359px;
  border-radius: 10px;
  background: #222222;
  border: 0.25px solid rgb(187 191 197 / 20%);
  box-shadow: 1px 1px 4px rgba(27, 49, 66, 0.11);
}

.uniqueBoxDrk1{
  border-radius: 10px;
  background: #222222;
  border: 0.25px solid rgb(187 191 197 / 20%);
  box-shadow: 1px 1px 4px rgba(27, 49, 66, 0.11);
}

.profileBox{
  height: 38px;
  width: 179px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
}

.profileBoxDrk{
  height: 38px;
  width: 179px;
  border-radius: 10px;
  background: #222222;
  border: 0.25px solid rgb(187 191 197 / 20%);
  box-shadow: 1px 1px 4px rgba(27, 49, 66, 0.11);
}


.paymentBox{

/*  border-radius: 10px;
  border: 0.5px solid #BBBFC5;*/
  box-shadow: 1px 1px 4px 0px #1B31421C;


}

.paymentBoxDrk{
  background: #222222;
  border: 0.25px solid rgb(187 191 197 / 20%);
  box-shadow: 1px 1px 4px rgba(27, 49, 66, 0.11);
}

.featureBox{
  height: 38px;
  width: 350px;
 


}

.descBox{
  height: 38px;
  width: 359px;
  border-radius: 10px;
  border-bottom: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;


}

.dateBox{
  height: 25px;
  width: 126px;
/*  border-radius: 10px;
*/  border-bottom: 0.5px solid #BBBFC5;
/*  box-shadow: 1px 1px 4px 0px #1B31421C;
*/

}

.uniqueBoxSearch{
  height: 38px;
  width: 231px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;


}

.uniqueBoxImg{
  height: 359px;
  width: 359px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;



}

.uniqueBoxDesc{
  height: 190px;
  width: 359px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
  margin-right: 1.3rem;
}

.uniqueDesc{
  height: 100px;
  width: 359px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
  margin-right: 1.3rem;
}

.uniqueDescDrk{
  height: 100px;
  width: 359px;
  border-radius: 10px;
  background: #222222;
  border: 0.25px solid rgb(187 191 197 / 20%);
  box-shadow: 1px 1px 4px rgba(27, 49, 66, 0.11);
  margin-right: 1.3rem;
}

.uniqueBoxSm{
  height: 107px;
  width: 107px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
  margin-right: 1.3rem;
}


.bulkTxt{
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;

}

.sendBtn{
  height: 32px;
  width: 157px;
  border: 1px solid #000000;
  border-radius: 50px;
}

.sendBtnDrk{
  height: 32px;
  width: 157px;
  border: 1px solid white;
  border-radius: 50px;
}


.sendBtn1{
  height: 32px;
  width: 107px;
  border: 1px solid #000000;
  border-radius: 50px;
}

.sendBtnDrk1{
  height: 32px;
  width: 107px;
  border: 1px solid white;
  border-radius: 50px;
}

.shareInput{
  background: #BBBFC5;
/* Blue-Grey Light */

border: 0.5px solid #BBBFC5;
}

.shareBox{
  width: 359px;
}


.coinBox{
  width: 829px;
}



.leftCol{
  width: 371px;
}

.rightCol{
  width: 829px
}

.tabTitle{
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 38px;
letter-spacing: 0em;
text-align: center;
color: #BBBFC5;


}


:root   { 
  --is-big: black; 
   --animate-delay: 5s;
   --webkit--animate-delay: 5s;
}

.is-big { --is-big: white; }

/*.block {
  padding: calc(
    4rem * var(--is-big) +
    1rem * (1 - var(--is-big))
  );
}*/


.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active {
font-size: 24px;
font-style: normal;
font-weight: 700 !important;
line-height: 38px;
letter-spacing: 0em;
color: var(--is-big);

}



.thumbImgBx{
height: 450px;
width: 380px;
border-radius: 20px;
background: #F6F7FA;
box-shadow: 14px 10px 35px 0px #1B314214;

}

.thumbImgBxDrk{
height: 450px;
width: 380px;
border-radius: 20px;
background: #222222;
box-shadow: 14px 10px 35px rgba(27, 49, 66, 0.08);

}

.thumbImg{
height: 282px;
width: 358px;
border-radius: 0px;
}

.smallCoin{
  height: 23px;
width: 23px;

}

.genLight{
font-size: 16px;
font-style: normal;
font-weight: 200;
line-height: 26px;
letter-spacing: 0em;
}

.blueBgBx{
  background: #F6F7FA;
border-radius: 20px;
box-shadow: 14px 10px 35px 0px #1B314214;

}

.newBlueBg{
  background: #443CCC;
}

.lightGrBg{
  background: #EFF0F2
}

.lightGrBg1{
  background: #F6F8FB
}

.reviewTxt{
  
    font-size: 13px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px;
    letter-spacing: 0em;

}

.reviewShadow{
  box-shadow: 2px 4px 11px rgba(142, 142, 142, 0.15);
}

.reviewShadowDrk{
  box-shadow: 2px 4px 11px 0px rgba(142, 142, 142, 0.15);
}


.StripeElement {
  height: 38px;
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
  height: 40px;
  padding: 10px 12px;

  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 1px 1px 4px 0px #1B31421C;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


.line{
  height: 0px;
  width: 53.0000000000002px;
  border-top-color: #DE7634;
  border-top-style: solid;
  border-top-width: 1px;
}

.greyCol{
  color: #838383;
}

.orangeCol{
  color: #DE7634;
}

.orangeBtn{
  height: 32px;
  width: 157px;
  border: 1px solid #DE7634;
  border-radius: 50px;
}

.orangeBtm{

  border-bottom: 1px solid #DE7634;
}


.editBtn{
  height: 32px;
  width: 111px;
  border-radius: 50px;
  border: 1px solid #000000;
}

.editBtnDrk{
  height: 32px;
  width: 111px;
  border-radius: 50px;
  border: 1px solid white;
}


.lineA{
  height: 0px;
  width: 371.0000000000014px;
  border-top-color: #EFF0F2;
  border-top-style: solid;
  border-top-width: 1px;
}

.unselected{
  color: #707070;
}

.orangeBg{
  background-color: #DE7634;
}

.orangeTxt{
  color: #DE7634;
}

.orangeBorder{
  border-color: #DE7634;
}

.blueBorder{
/*  border-color: rgba(11, 26, 44, 1);
*/  border-color: rgba(68, 60, 204, 1);
  border-width: 1px;
  border-style: solid; 

}

.signupBx{
border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;
}

.whiteLine{
  border-bottom: 1px solid white !important;
}

td,
th {
  text-align: left;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  padding-left: 0.96667rem;
  padding-right: 0.96667rem;
  padding-top: 0.725rem;
  padding-bottom: calc(0.725rem - 1px);
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}

table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
  width: 100%;
}

thead {
  text-align: left;
}

.grid-container {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto auto;
  width: 620px;
}

.grid-item {
/*  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);*/
 
  text-align: left;
}

.grid-item1 {


  text-align: left;
}


.stdBtn{
  height: 32px;
  width: 106px;
  left: 1214px;
  top: 42px;
  border-radius: 50px;
  background: #DE7634;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 4px;

}

.smallBox{
  position: relative;
  width: 720px;
  height: 727px;
  background: #FFFFFF;

  box-shadow: 6px 16px 26px rgba(27, 49, 66, 0.11);
  border-radius: 20px;
  padding: 0 20px;
}
.bullk-form {
  width: 100%;
}


.stdInput{
  height: 38px;
  width: 300px;
  border-radius: 10px;
  border: 0.5px solid #BBBFC5;
  box-shadow: 1px 1px 4px 0px #1B31421C;

}

.gryLine{
  position: absolute;
width: 620px;
height: 0px;
left: 50px;
top: 102px;

/* Grey-light */

border: 1px solid #EFF0F2;
}

.shareLine{
  border-top: 0.25px solid #E4E4E4
}

.normalBold{
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;

}

.aboutgrad{
   background-image: url(./images/gradabout.png);
       background-size: 158%;
    background-position: 12%;
}

.h3Sub{
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
}

.gryLine1{
width: 620px;
height: 0px;
border: 1px solid #EFF0F2;
}

.borderBoundary{
  width: 620px;
}



input[type=file] {

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

}

.calcBox{
  width: 720px;
  height: 450px;
  background: #F6F7FA;
  box-shadow: 14px 10px 35px rgba(27, 49, 66, 0.08);
  border-radius: 20px;

}

.calcDrkBox{
  width: 720px;
  height: 450px;
  border-radius: 20px;
  background: #495461;
  box-shadow: 6px 16px 26px rgba(0, 0, 0, 0.2);

}

.heroFont{
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: 72px;
letter-spacing: 0em;
text-align: left;

}

.h3Light{
font-size: 26px;
font-style: normal;
font-weight: 200;
line-height: 38px;
letter-spacing: 0em;
text-align: left;

}


.rectangle{
  height: 445px;
  width: 493px;
  right: 20px;
  top: 40px;
  border-radius: 20px;
  background: rgba(246, 247, 250, 1);
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);
  z-index: -1;

}

.rectangle1{
  height: 445px;
  width: 493px;
  right: 20px;
  top: 40px;
  border-radius: 20px;
  background: #222222;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);
  z-index: -1;

}

.rectangle2{

  border-radius: 20px;
  background: #222222;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);


}






.carousel-indicators [data-bs-target] {
    opacity: 0.3 !important

}


.carousel-indicators .active{
  background-color: rgba(222, 118, 52, 1) !important;
  opacity: 1 !important
}

.carousel-control-next-icon {
    background-image: url(./images/snex.svg) !important;
/*    background-image: url(data:image/svg  xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
*/}

.carousel-control-next-iconD {
    background-image: url(./images/snexD.svg) !important;
/*    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
*/}

.carousel-control-next {
  opacity: 1 !important
}
.carousel-dark .carousel-control-next-icon {
    filter: none !important;
}


.carousel-control-prev-icon {
    background-image: url(./images/sprev.svg) !important;
/*    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
*/}

.carousel-control-prev-iconD {
    background-image: url(./images/sprevD.svg) !important;
/*    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
*/}


.carousel-control-prev {
  opacity: 1 !important
}
.carousel-dark .carousel-control-prev-icon {
    filter: none !important;
}

.topLine{
  border-top-style: solid;
  border-top-color: rgba(222, 118, 52, 1);
  border-top-width: 3px;
}

.miniText{

font-size: 16px;
font-style: normal;
font-weight: 200;
line-height: 26px;
letter-spacing: 0em;
text-align: right;

}

.bluebg{
/*  background-color: rgba(11, 26, 44, 1);
*/  background-color: rgba(68, 60, 204, 1);
  background-color: #443CCC;

}

.grybg{
  background-color: rgba(187, 191, 197, 1);

}

.findBtn{
  height: 50px;
  width: 200px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 1);
}

.findDrkBtn{
  height: 50px;
  width: 200px;
  border-radius: 50px;
  border: 1px solid black;
}

.findTxt{
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;

}

.blueTxt{
  color: rgba(52, 38, 201, 1);
}

.answerBox{
/*  background: rgba(44, 57, 73, 1);
*/  background: #443CCC;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.15);
  border-radius: 10px;
}


.answerDrkBox{
/*  background: rgba(73, 84, 97, 1);
*/  
  background: #443CCC;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.15);
  border-radius: 10px;
}

.answerBox1{
/*  background: rgba(44, 57, 73, 1);
*/  
/*background: white;
*/  
  background: #443CCC;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.15);
  border-radius: 10px;
  color: white;
}

.aboutBorder{
  border-color: #aeabe2;
}



.answerDrkBox1{
/*  background: rgba(73, 84, 97, 1);
*/  
  background: #443CCC;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.15);
  border-radius: 10px;
  color: white;
}

.questionTxt{
/*  color: rgba(255, 255, 255, 0.5);
*/
color: white;
background: rgba(68, 60, 204, 0.5);
 box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.15);
  border-radius: 10px;

}

.unSelectedTxt{
/*  color: #707070;
*/
color: white;
background: rgba(68, 60, 204, 0.5);
 box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.15);
  border-radius: 10px;
}


.questionTxt1{
   color: rgba(0, 0, 0, 0.5);
}

.unSelectedTxt1{
  color: #707070;


}


.conversionBox{
  background: #FFFFFF;
border: 0.5px solid #BBBFC5;
box-sizing: border-box;
box-shadow: 1px 1px 4px rgba(27, 49, 66, 0.11);
border-radius: 10px;

}


.identityCard{
  height: 430px;
  width: 300px;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  background: white;
}

.identityCard:hover {
  box-shadow: 6px 16px 26px 0px #1B31421C;
}

.identityCardDrk{
  height: 430px;
  width: 300px;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  background: #1A1A1A;
}

.identityCardDrk:hover {
  box-shadow: 6px 16px 26px 0px #5A5A5A0F;
}

.aboutGen{
font-size: 20px;
font-weight: 300;
line-height: 32px;


}

.aboutHeader{

font-size: 35px;
font-weight: 700;
line-height: 56px;


}


.backgroundShape{
  position: absolute;
background: #F6F7FA;
border-bottom-left-radius: 20px;
}

.backgroundDrkShape{
    position: absolute;
background: #222222;
border-bottom-left-radius: 20px;
}

.borderIcon{
  border-color: #DBDBDB;
}

.borderIconB{
  border-color: black;
}

.titleBox{
  background: #3227C1;
border-radius: 10px;
}


.itemBox{
  background: rgba(246, 247, 250, 1);
  height: 43rem;
  border-radius: 10px 10px 10px 10px;

}

.itemBoxDrk{
  background: #222222;
  height: 43rem;
  border-radius: 10px 10px 10px 10px;
}

.itemBoxSm{
  background: rgba(246, 247, 250, 1);
  height: 30.5rem;
  border-radius: 10px 10px 10px 10px;
}

.itemBoxSmDrk{
  background:#222222;
  height: 30.5rem;
  border-radius: 10px 10px 10px 10px;
}

.itemBox:hover{
  background: rgba(246, 247, 250, 1);
  height: 43rem;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);
  border-radius: 10px 10px 10px 10px;

}

.itemBoxDrk:hover{
  background: #222222;
  height: 43rem;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);
  border-radius: 10px 10px 10px 10px;
}

.itemBoxSm:hover{
  background: rgba(246, 247, 250, 1);
  height: 30.5rem;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);
  border-radius: 10px 10px 10px 10px;
}

.itemBoxSmDrk:hover{
  background:#222222;
  height: 30.5rem;
  box-shadow: 14px 10px 35px 0px rgba(27, 49, 66, 0.08);
  border-radius: 10px 10px 10px 10px;
}

.darkTitleBg{
  background: #1A1A1A;
  border-radius: 0px 0px 10px 10px;
}


.avatar{
  width: 147px;
  height: 147px;
  background: white;
/* Blue-Grey Light */

border: 1px solid white;
box-sizing: border-box;
}

.avatarDrk{
  width: 147px;
  height: 147px;
  background: rgba(26, 26, 26, 1);
  box-sizing: border-box;
}

.avatar1{
  width: 147px;
  height: 147px;
  background: white;
/* Blue-Grey Light */
}

.getLightIta{
font-size: 20px;
font-style: normal;
font-weight: 200;
line-height: 32px;
letter-spacing: 0em;
text-align: left;

}


 .contentMarkdown > p{
  margin-bottom: 2rem;
}

.darkbg
{
  background: rgba(26, 26, 26, 1);

}

.dark

.genLight > b{
  font-weight: 700;
}

.uploadSketch{
    top: 32%;
    right: 35%;
}

.checkBx{
  width: 25px;
  height: 25px;
  border: 1px solid #BBBFC5;
  box-sizing: border-box;
  border-radius: 5px;
}

.searchThumb{
  background: #F6F7FA;
  box-shadow: 14px 10px 35px rgba(27, 49, 66, 0.08);
  border-radius: 20px;
  height: 28rem;
}

.searchThumbDrk{
  height: 28rem;
  background: #222222;
  box-shadow: 14px 10px 35px rgba(27, 49, 66, 0.08);
  border-radius: 20px;
}


.orgBdr{
  border-top: 1px solid rgba(222, 118, 52, 1)
}

.dFaITY {
  background-color: white !important;
}

.gryLine2{
/*  position: absolute;
width: 620px;
height: 0px;
left: 50px;
top: 102px;

/* Grey-light */

border: 1px solid #EFF0F2;
}

.gryLine2Drk{
/*  position: absolute;
width: 620px;
height: 0px;
left: 50px;
top: 102px;

/* Grey-light */

border: 1px solid white;
}

.picBg{
  width: 540px;
  height: 550px;
  background: #F6F7FA;
  box-shadow: 14px 10px 35px rgba(27, 49, 66, 0.08);
  border-radius: 20px;

}

.picDrkBg{
  width: 540px;
  height: 550px;
  background: #222222;
  box-shadow: 6px 16px 26px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.darkBx{
  background: #222222;
/*  box-shadow: 6px 16px 26px rgba(0, 0, 0, 0.2);
*/}


.searchBx{
  width: 640px;
  height: 60px;
  border: 1px solid #BBBFC5;
  box-sizing: border-box;
  filter: drop-shadow(6px 16px 26px rgba(27, 49, 66, 0.11));
  border-radius: 50px;
}

.searchBxDrk{
  width: 640px;
  height: 60px;
  box-sizing: border-box;
  background: #2C3949;
  box-shadow: 6px 16px 26px rgba(27, 49, 66, 0.11);
  border-radius: 50px;
}



.searchBxHome{
  width: 592px;
  height: 60px;
  box-shadow: 6px 16px 26px 0px #1B31421C;
  background: #5346DC;
  border-radius: 50px;
}

[type='checkbox'], [type='radio']  {
  color: #443CCC !important;
  --tw-ring-color: transparent !important;

}


.noRing{
    --tw-ring-color: transparent !important;
}

.socialBx{
  width: 30px;
}


.copy{
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
}

.searchBxHome > ::placeholder {
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 0.65);


}

.borderbot{
  border-bottom-width: 3px;
}


.darkInput{
  background: #222222;
/* Blue-Grey Light */

border: 0.5px solid #BBBFC5;
}
  
  .darkInputBg{
  background: #222222;
/* Blue-Grey Light */

}









/*.slide-container {
  width: 70%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1
}*/

.gown{
  margin-right:auto;
  margin-left: auto; 
}

@media screen and (min-width: 860px) {
   .slide-container  {
    width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1
   }
 };

 @media screen and (min-width: 1020px) {
   .slide-container  {
     width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1
   }
 };

 @media screen and (min-width: 767px) {
   .slide-container  {
   width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1
   }
 };

 @media screen and (min-width: 620px) {
   .slide-container  {
     width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1
   }
 };

 @media screen and (min-width: 420px) {
   .slide-container  {
    width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1
   }
 }

 @media screen and (min-width: 860px) {
   .gown  {
    margin-top: 4rem;
  margin-right: 16rem;
  margin-left: 0;;
  margin-bottom: 0;
 
   }
 };

 @media screen and (min-width: 1020px) {
   .gown  {
      margin-top: 4rem;
  margin-right: 16rem;
  margin-left: 0;;
  margin-bottom: 0;
   }
 };

 @media screen and (min-width: 767px) {
   .gown  {
    margin-top: 4rem;
  margin-right: 16rem;
  margin-left: 0;;
  margin-bottom: 0;
   }
 };

 @media screen and (min-width: 620px) {
   .gown  {
      margin-top: 4rem;
  margin-right: 16rem;
  margin-left: 0;;
  margin-bottom: 0;
   }
 };

 @media screen and (min-width: 420px) {
   .gown  {
     margin-top: 4rem;
  margin-right: 16rem;
  margin-left: 0;;
  margin-bottom: 0;
   }
 }


h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: white;
}

 @media screen and (min-width: 860px) {
   .each-fade h2  {
   width: 25%;
 
   }
 };

 @media screen and (min-width: 1020px) {
   .each-fade h2  {
      width: 25%;
   }
 };

 @media screen and (min-width: 767px) {
   .each-fade h2  {
     width: 25%;
   }
 };

 @media screen and (min-width: 620px) {
   .each-fade h2  {
      width: 25%;
   }
 };

 @media screen and (min-width: 420px) {
   .each-fade h2  {
      width: 25%;
   }
 }

.frontfont{
  font-family: 'PT Sans', sans-serif;
  color:  grey;
}


/*.carousel-item {
      transition: -webkit-transform .4s ease-in-out !important;
    transition: transform .4s ease-in-out !important;
    transition: transform .4s ease-in-out,-webkit-transform .4s ease-in-out !important;
}*/

.carousel-fade .carousel-item {
    opacity: 1;
    transition-property: opacity;
    transform: none;
    transition-delay: 1s;
    -webkit-animation-delay: 6s !important;
    -webkit-animation-delay: 6s !important;

}



.carousel-item.carousel-fade {

-webkit-animation-name: fade-in !important;
-webkit-animation-duration: 3s !important;
-webkit-animation-delay: 6s !important;

}


.spaceMark > p  {
  margin-top: 2rem;
}

.partnerHeader{

font-size: 35px;
font-weight: 700;
line-height: 56px;
letter-spacing: 0em;

}

strong{
  font-weight: 700;
}

p{
  margin-bottom: 1rem;
}



p > a {
  text-decoration: underline;
}




p > strong {
  color: #DE7634;
}


.react-player__preview{
  border-radius: 1.1rem;
}

.menu-phone .darkBx {
  background-color: #1a1a1a;
}
.line-dot.line-dot2 {
  display: none;
}
.express-bg.ph {
  display: none;
}
.sort-button {
  display: none;
}
.ercd-phone .items-start {
  display: none;
}
.upload-creadits-phone {
  display: none;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-size: 100% 100%;
}


/* awdasdd */
.creative-row {
  display: flex;
  flex-direction: column;
}
.creative-row .w-1\/2 {
  width: 100%;
}
.patner-orange-2 {
  margin-top: 314px;
}
.increase-row {
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.increase-row .blueBg1 {
  width: 33%;
  text-align: center;
}
.increase-row .creative-row .h3Bold {
  width: 100%;
}
.increase-row .creative-row .h3Light {
    text-align: center;
    width: 100%;
}
@media screen and (min-device-width: 320px) and (max-device-width: 767px) { 
  .how-it-work-video {
    display: flex;
    flex-direction: column;
    
}

.how-it-work-video .w-1\/2 {
    width: 100%;
}
  .increase-row {
    flex-direction: column;
    padding: 50px 0px;
}

.increase-row .blueBg1 {
    width: 100%;
}

.increase-row .blueBg1 .py-20 {
    padding-bottom: 20px;
    padding-top: 20px;
}
  .single-page .picDrkBg {
    max-width: 100%;
  }
  
  .pot-home {
    width: 100%;
}
.book-modal {
  width: 100% !important;
}
  .sort-button {
    display: block;
}
  .express-bg.ph {
    display: block;
    margin-bottom: 25px;
}
  .line-dot.line-dot2 {
    display: block;
}
.setup-bysetup {
  padding-bottom: 63px;
}
  .menu-phone .darkBx {
    background: rgb(40 39 39) !important;
  }
  .home-banner {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
}

.home-content .heroFont {
  font-size: 30px;
  line-height: inherit;
  margin: 0;
}
.home-content .h3Light {
  line-height: inherit;
  font-size: 18px;
  text-align: left;
}
.renters-gallray {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px;
  padding-bottom: 50px;
  justify-content: center;
}
.how-it-bg {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.explore-bg {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  justify-content: space-between;
}

.explore-bg a {
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  padding: 20px 15px;
  padding-bottom: 45px;
}
.explore-bg .identityCard .genLight {
  font-size: 14px;
  line-height: inherit;
}
.footer-row {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.sectWidth {
  width: auto;
}
.social-icon {
  justify-content: flex-start;
}
.searchBxHome {
  width: 100%;
}
.searchBxHome {
  width: 100%;
}

.home-content {
  width: 100%;
}
.home-img {
  width: 100%;
  margin-bottom: 50px;
}

.home-img img {
  width: 100%;
  margin: 0;
}

.home-img2 {
  margin: 0;
}

.carousel-item {
  height: auto !important;
}
.renters-gallray .w-60 {
  width: 47%;
  margin: 0 4px;
}
.sem-title {
  padding: 0 15px;
  padding-top: 50px;
}

.sem-title h2 {
  font-size: 30px;
  line-height: inherit;
}
.how-it-bg .outlineText1 {
  font-size: 40px;
  line-height: inherit;
  position: absolute;
  left: -12px;
  top: -21px;
}

.how-it-bg .outlineText1Drk {
  font-size: 40px;
  line-height: inherit;
  position: absolute;
  left: -12px;
  top: -21px;
}
.how-box-card-bg {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 25px 10px;
  height: 100%;
}
.how-box-card .genLight {
  font-size: 12px;
  line-height: inherit;
  margin-top: 10px;
}
.how-box-card img.w-full {
  width: 30px;
}
.how-box-card {
  margin: 0 9px;
  width: 41%;
  margin-top: 40px;
}
.line-dot {
  top: 16px;
  width: 302px;
}

.line-dot.line-dot2 {
  top: 35px;
}


.how-box-card-bg .h3Bold {
  font-size: 16px;
  line-height: inherit;
}

.backgroundDrkShape {
  position: relative;
}
.backgroundShape {
  position: relative;
}
.explore-bg .identityCard {
  box-shadow: 6px 16px 26px 0 rgba(27,49,66,.10980392156862745);
  width: 48%;
  height: auto;
}
.explore-bg .identityCardDrk {
  background-color: #262626;
  width: 48%;
  height: auto;
}
.explore-bg .identityCard .genLight,.explore-bg .identityCardDrk .genLight {
  font-size: 14px;
  line-height: inherit;
}
.explore-bg a img.w-100 {
  width: 30px;
}
.asked-box {
  padding: 20px;
  margin-top: 15px;
}

.asked-box div {
  font-size: 18px;
  padding: 0;
  line-height: inherit;
}
.menu-phone {
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  flex-direction: row-reverse;
}
.footer-row .w-1\/5 {
  width: 100%;
}
.footer-bg {
  padding: 0;
  padding-top: 50px;
}
.copy-right {
  padding: 50px 0px;
}
.nav-left {
  display: flex;
  align-items: center;
}

.nav-left .ml-20 {
  margin-left: 20px;
  margin-top: 6px;
}
.man-logo {
  position: absolute;
  width: 84px;
  left: 15px;
  top: -4px;
}
.menu-phone .ml-20 {
  margin-left: 0;
  position: absolute;
  left: 112px;
}
.colsaps-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  margin: 0;
}
.colsaps-nav a {
  margin-bottom: 10px;
}
.setup-bysetup .mat-home {
  font-size: 18px;
  line-height: inherit;
  margin-top: 30px;
  text-align: left;
}

.renters-gallray .genBold {
  font-size: 9px;
}

.renters-gallray .genLight {
  font-size: 10px;
}

.renters-gallray .reviewTxt {
  font-size: 12px;
  padding: 0 9px;
  padding-top: 10px;
}

/**********signup page***********/
.signup-page {
  padding: 0 15px;
  width: auto;
}

.signup-page .uniqueBox {
  width: 100%;
}

.signup-page .shareBox {
  width: 100%;
  padding: 0 15px;
}
/**********signup page***********/
.login-page .uniqueBox {
  width: 100%;
}

.login-page .shareBox {
  width: 100%;
}
/******credits page**********/
.credits-page {
  padding: 0 15px;
}

.credit-h2 {
  font-size: 30px;
  line-height: inherit;
}

.credits-page .credit-h3 {
  font-size: 17px;
  line-height: inherit;
  text-align: left;
}

.shear-card.answerBox1,.shear-card.answerDrkBox1 {
  padding: 25px 12px;
}
.shear-card .h3Bold {
  font-size: 15px;
  line-height: inherit;
}
.shear-card  .pt-12 {
  margin: 0 3px;
}

.shear-card .genBold {
  font-size: 12px;
  line-height: inherit;
  margin-top: 20px;
}
.accodian-tabs .pt-12 {
  margin: 0;
}
.aroor-left-5 {
  position: relative;
  width: 12rem;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.aroor-left-5 img {
  width: auto !important;
}
/******partners page*******/
.brandes-section {
  padding: 0 15px;
  position: relative;
}

.brandes-section h2 {
  font-size: 21px;
  line-height: inherit;
  margin: 0;
  position: absolute;
  left: 15px;
  right: 0;
  width: 100%;
  top: 0;
}

.brandes-section .h3Light {
  font-size: 18px;
  line-height: inherit;
  margin: 0;
  margin-top: 67px;
  margin-right: 0;
  width: 100%;
  padding-right: 10px;
}

.brandes-img {
  top: 90px;
}

.patner-orange {
  margin: 0;
  align-items: flex-start;
  text-align: left;
}

/* .brandes-img img {
  border-radius: 0;
} */

.why-text {
  font-size: 15px;
  text-align: left;
  margin-left: 9px;
}
.outlineText {
  font-size: 50px;
  line-height: inherit;
}


.increase-section .blueBg1,.blueBg2,.blueBg3 {
  padding: 0 15px;
}

.partnerHeader {
  font-size: 16px;
  line-height: inherit;
}

.h3Bold {
  font-size: 14px;
  line-height: inherit;
  margin: 0;
  margin-top: 20px;
  opacity: 1;
}

.h3Light {
  text-align: center;
  font-size: 20px;
  line-height: inherit;
}

.increase-section .flex.flex-row.w-full {
  align-items: center;
}

.how-box-card .blueBg2 {
  padding: 25px 10px;
}


.how-box-card .blueBg3 {
  padding: 25px 10px;
}

.in-form-left {
  width: 100%;
  padding: 84px 15px;
}

.profile-top .uniqueBox,.profile-top .uniqueBoxDrk {
  width: 100%;
}

.uniqueBoxDesc {
  width: 100%;
}

.form-row {
  flex-direction: column;
  position: relative;
}

.form-row .inimg-2 {
    position: absolute;
    left: 0;
    top: -54px;
    right: 0;
}

.form-row .inimg-2 img {
    max-width: 39% !important;
    margin-left: auto;
    border-radius: 0.375rem;
}
.in-form-left .uniqueBox {
  width: 100%;
}
.in-form-left .partnerHeader {
  width: 60%;
}

.in-form-left .h3Light {
  text-align: left;
  font-weight: 500;
  line-height: inherit;
  font-size: 16px;
  margin-top: 15px;
}
.partners-page2 {
  overflow: hidden;
}
.brandes-section .h3Light {
  text-align: left;
}
.patner-orange {
  align-items: center;
}
/*******type********/
.box-25 p {
  font-size: 10px;
  height: auto;
  margin: 0;
}

.box-25 .box-slae-15 {
  margin: 0;
}

.box-25 .orangeBg {
  width: 126px;
  margin-left: 8px;
  font-size: 11px;
  margin-top: 0;
}

.box-25 .box-slae-15 .genLight {
  line-height: inherit;
}
.item-box22 p {
  font-size: 10px;
  margin: 5px 0;
}

.item-box22 .spaceMark {padding: 0px 35px 0px 12px;margin-top: 25px;}

.item-box22 {
  width: 100%;
}
.box-16 {
  margin: 0 0px;
}

.item-box22 .orangeBg {
  width: 126px;
  margin-left: 8px;
  font-size: 11px;
  margin-top: 15px;
}

.box-16 .h3Dark24 {
  text-align: left;
  padding-left: 12px;
}
.need-video-row {
  flex-direction: column-reverse;
}

.get-inspired .h3Dark24 {
  font-size: 11px;
}
.get-inspired img {
  width: 20px;
  height: 20px;
}
.need-some-inspiration .h3Dark24 {
  font-size: 11px;
}

.video-wd {
  width: 100% !important;
  height: 215px !important;
}

.react-player__preview {
  width: 100%;
  /* height: 300px; */
}

.playear-video {
  width: 100%;
}


.get-inspired {
  padding: 0 5px;
}

.express-bg.ds {
  display: none;
}
.type-page {
  padding: 50px 15px;
  padding-bottom: 0;
}

.get-title {
  padding: 0 15px;
  text-align: left;
}

.get-title .h3Light {
  text-align: left;
  font-size: 18px;
}

.get-title h2.mb-8 {
  margin: 0;
  font-size: 30px;
}

.express-bg {
  padding: 20px;
  height: auto;
}

.express-bg h2 {
  font-size: 25px;
  line-height: inherit;
  margin-bottom: 10px;
}

.express-bg .h3Dark {
  font-size: 15px;
  line-height: inherit;
  font-weight: 400;
}
.express-box .h3Dark24 {
  font-size: 13px;
  line-height: inherit;
  position: relative;
  top: 14px;
}

.express-box .bg-white {
  height: auto;
}

.express-box .darkTitleBg {
  height: auto;
}
.express-box .itemBox,.express-box .itemBoxDrk {
  height: 418px;
  margin: 0;
  display: flex;
  align-items: center;
}
.express-box:first-child {
  margin: 0;
}

.item-box3 {
  height: 200px !important;
  margin-bottom: 19px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-slae-15 .px-8.mt-16 {
  margin: 0;
  padding: 3px 10px;
}
.item-box3 .pt-8 {
  height: auto !important;
  padding: 0;
}
.item-box3 img {
  width: 80px;
  height: auto;
}
.express-box  .itemBox img {
  height: auto;
}
.need-5-title h2 {
  margin: 0;
  font-size: 28px;
  line-height: inherit;
}

.need-5-title .h3Light {
  width: 100%;
  text-align: left;
  margin-top: 15px;
  font-size: 17px;
}

.need-5-title {
  margin-top: 34px;
}
.video-twxt {
  width: 100%;
  padding-top: 25px;
}
.need-video-row p {
  font-size: 17px;
  line-height: inherit;
  margin-bottom: 10px;
}

.video-twxt .getLightIta {
  margin-top: 25px;
}
.video-mr {
  margin: 0;
}
.video-twxt .h3Dark24 {
  text-align: center;
  font-size: 19px;
  margin: 0;
  margin-bottom: 12px;
}

.video-twxt .h3Sub {
  text-align: center;
  width: 100%;
  font-size: 12px;
  line-height: inherit;
}
.type-page-pad {
  padding: 50px 0px;
  padding-bottom: 0;
}
/******contact**********/
.contact-row {
  display: flex;
  flex-direction: column-reverse;
}

.contact-page {
  padding: 15px;
}

.sub-mith-button {
  width: 100px;
  margin-right: 13px;
}

.form-button {
  text-align: right;
}
.contact-bg {
  margin-top: 0;
  padding-bottom: 30px;
}
/**********about********/
.about-section {
  flex-direction: column;
  padding: 0 0px;
  padding-top: 30px;
}

.about-product {
  padding: 0;
  width: 100%;
  padding-top: 50px;
}
.johin-row {
  flex-direction: column-reverse;
  padding: 0 20px;
}

.rent-img {
  width: 100% !important;
  border-radius: 0;
}

.rent-img img {
  width: 100%;
  border-radius: 0;
}

.johin-row .aboutgrad {
  width: 100% !important;
  background-size: cover;
  margin-top: -12px;
}
.about-line-dot img {
  width: 44%;
}

.about-line-dot {
  right: 0;
  display: none;
}

.about-stand {
  width: 100%;
}
.about-section .imgBx {
  width: 100%;
}

.what-we-stand-row {
  padding: 0 15px;
  margin-top: 50px;
}
.about-bg-p {
  padding: 40px 15px;
}

.about-bg-p h2 {
  font-size: 35px;
}

.shear-card img {
  width: 60px;
}
.about-phone-hide {
  display: none;
}
.about-logo .about-row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  padding: 0;
}

.about-logo img {
  width: 130px;
}
.about-page p {
  font-size: 17px;
  line-height: inherit;
}
.about-page .aboutHeader {
  font-size: 30px;
  margin-bottom: 10px;
}

/**********profile page***********/
.profile-row {
  flex-direction: column;
  padding: 0 15px;
  margin-top: 0px;
}

.profile-top {
  flex-direction: column;
  margin-top: 40px;
}

.profile-top .sendBtn,.profile-top .sendBtnDrk {
  width: 149px;
  margin: 0 0px;
}
.bulak-row {
  display: flex;
  justify-content: space-between;
}
.profile-top .shareBox {
  width: 100%;
  margin-top: 20px;
}
.profile-conn-box h2 {
  font-size: 30px;
  margin-top: 20px;
  line-height: inherit;
}


.profile-creatits {
  flex-direction: column;
  padding: 0 15px;
}

.profile-creatits .rightCol {
  width: 100%;
  margin: 0;
}

.profile-creatits .leftCol {
  width: 100%;
}

.profile-conn-box {
  width: 100%;
  margin: 0;
}
.profile-row .imgBx {
  width: 100%;
  height: 304px;
}
.lineA.mt-8.bottom-line {
  width: auto;
}
.faq-asked {
  text-align: left;
  font-size: 18px;
}
/*********search***********/
.search-title {
  flex-direction: column;
  padding: 0 15px;
  margin-bottom: 25px;
}
.search-title h2 {
  font-size: 30px;
  line-height: inherit;
  margin-bottom: 20px;
}

.search-title .searchBx,.search-title .searchBxDrk {
  width: 100%;
}

.serch-row {
  flex-direction: column;
  margin-top: 25px;
}

.serch-left {
  width: 100%;
  display: none;
}
.serch-right {
  width: 100%;
  padding: 0 10px;
}

.serch-right .gap-5 {
  flex-wrap: wrap;
  display: flex;
}
.products-cars-serch {
  height: auto;
  margin-bottom: 20px;
  margin-top: 12px;
}
.serch-right .gap-5 a {
  width: 46%;
  height: auto;
  padding-bottom: 15px;
}
.serch-page {
  margin: 0px;
  padding-top: 50px;
}
.sort-button {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.sort-button button {
  width: 100%;
  border-radius: 0;
  border: 1px solid #eee;
  box-shadow: unset;
  padding: 10px 0px;
}

.computer-bx {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.computer-bx .genLight {
  line-height: inherit;
  font-size: 12px;
}

.serch-box-day {
  padding: 0 10px;
}

.card-bar {
  margin: 0;
  padding: 5px 10px;
  font-size: 12px;
  line-height: inherit;
  width: 100%;
}

.serch-box-day h3 {
  font-size: 18px;
}

.products-cars-serch img {
  width: 100px;
  height: 100px;
}
.credits-page .questionTxt1,.credits-page .unSelectedTxt1 {
  font-size: 19px;
}
/*******profile top******/
.upload-creadits-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.upload-creadits-phone {
  width: 60%;
  padding: 0;
  padding-left: 12px;
  padding-top: 25px;
}

.upload-creadits-phone h2 {
  font-size: 19px;
  line-height: 46px;
}

.upload-creadits-row .imgBx {
  position: relative;
  height: 185px;
  display: flex;
  flex-direction: column;
}
.full-img1 img {
  position: absolute;
  bottom: 0;
  height: 185px;
}
.save-fixed {
  position: absolute;
  bottom: -38px;
  left: 46px;
}

.upload-creadits-phone .editBtn,.upload-creadits-phone .editBtnDrk {
  margin: 0;
  font-size: 11px;
  margin-left: 12px;
  height: auto;
}

.upload-creadits-phone .gen {
  font-size: 12px;
}

.upload-creadits-phone .genBold {
  font-size: 10px;
}
.upload-creadits-phone .mt-4.mr-4 {
  margin: 0;
}
.upload-creadits-phone .mt-4.mr-4 {
  margin: 0;
}

.upload-creadits-row .imgBx img.w-100 {
  width: 40px;
}

.upload-creadits-row .imgBx .uploadSketch {
  top: 24%;
  right: 22%;
}

.upload-creadits-phone .lineA.mt-8.bottom-line {
  margin: 0;
}
.ercd-phone .items-start {
  display: none;
  font-size: 30px;
  text-align: center;
  margin-top: 30px;
}
.ercd-phone {
  margin-top: 50px;
}

.upload-creadits-phone {
  display: block !important;
}
.upload-creadits-phone .leftCol {
  display: block !important;
}
.destop-block {
  display: block;
}
.profile-creatits .leftCol {
  display: none;
}
.coin-box-align .coin {
  width: 30px;
  height: 30px;
}

.coin-box-align h3 {
  font-size: 20px;
}

.coin-box-align .authBtn {
  font-size: 10px;
  width: 67px;
  margin-left: 11px;
  height: 29px;
}
.profile-modal {
  width: 100% !important;
}

.profileBox {
  width: 100%;
}

.profile-name {
  flex-direction: column;
}

.uniqueDesc {
  width: 100%;
}

.uniqueBox {
  width: 100%;
}

.profile-modal .shareBox {
  width: 100%;
}

.profile-name .flex.flex-col {
  margin-bottom: 15px;
}

.profile-update-button {
  position: relative;
  top: -10px;
}
.Top-up-form {
  width: 100% !important;
}
.bullk-form {
  width: 100%;
  padding: 0 13px;
}

.bullk-form .smallBox {
  width: 100%;
  padding: 0 15px;
  height: 930px;
}

.bullk-form .gryLine {
  width: 100%;
  left: 0;
  right: 0;
}

.bullk-form .gryLine1 {
  width: 100%;
}
/*******profile end******/
.basket-form {
  width: 100% !important;
}
.basket-form form.max-w-xl.mx-auto.px-10 {
  padding: 0 15px;
}
/*****listing****/
.single-page {
  flex-direction: column;
  padding: 30px 15px;
}

.picBg, .picDrkBg {
  width: auto;
  height: auto;
}

.mack-book-card-1 {
  width: 100%;
}
.points-5 .h3Bold {
  font-size: 24px;
  margin: 0;
}
.mack-book-card-2 {
  width: 100%;
  margin-top: 30px;
}
.check-siz {
  font-size: 20px;
}
.mack-book-card-2 h2 {
  font-size: 30px;
  line-height: inherit;
}
.book-button {
  font-size: 15px;
  padding: 7px 26px;
}

.mack-book-card-2 .genLight {
  padding: 0;
}
.privacy-page p {
    font-size: 18px;
    text-align: left;
    line-height: inherit;
    font-weight: 300;
}

.privacy-page {
    padding-bottom: 0;
}
.privacy-page h2 {
  font-size: 30px;
  line-height: inherit;
}
.top-mr {
  margin: 20px 0px;
}
.your-bust {
  height: auto !important;
}
.date-9 .genBold {
  font-size: 12px;
}

.date-9 h3 {
  font-size: 17px;
}
.date-10 h3 {
  font-size: 17px;
}
.next-modal {
  height: auto !important;
}

.next-modal .mt-4 {
  margin: 0;
}

.next-modal .h3Bold {
  margin: 0;
  font-size: 16px;
  margin-bottom: 5px;
}
.upcoming-row {
  flex-wrap: wrap;
  display: flex;
}

.upcoming-row a .thumbImg {
  width: auto;
}

.upcoming-row a {
  height: inherit;
  width: 46%;
  height: auto;
  padding-bottom: 15px;
}
.let-title {
  font-size: 30px;
}
.computer-bx2 {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.computer-bx3 {
  padding: 0;

}
.computer-bx3 h3 {
  font-size: 18px;
}

.computer-bx3 .gen {
  font-size: 12px;
  line-height: inherit;
}

.computer-bx3 .orangeCol {
  font-size: 12px;
}

.computer-bx4 .gen {
  font-size: 12px;
  line-height: inherit;
  padding: 0;
}

.computer-bx4 {
  padding-top: 10px;
}
.brand-mobile-row fieldset {
  height: 200px;
  overflow: scroll;
  margin-left: 15px;
  margin-bottom: 30px;
}
.serch-page .h3Sub {
  padding: 0 15px;
}
.about-section img {
  object-fit: unset;
}
.upload-creadits-row .imgBx img {
  object-fit: unset;
}
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) { 
  .let-title {
      font-size: 30px;
  }
  .book-button {
    font-size: 15px;
    padding: 7px 26px;
  }
  .menu-phone .darkBx {
    background: rgb(40 39 39) !important;
  }
  .renters-gallray {
    flex-wrap: wrap;
    margin: 0;
    padding: 0 20px;
    justify-content: center;
}

.explore-bg {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 88px;
}
.sectWidth {
    width: auto;
}



.how-it-bg {
    display: flex;
    flex-wrap: wrap;
}

.searchBxHome {
    width: auto;
}

.home-content h2 {
  font-size: 30px;
  line-height: inherit;
  margin-top: 0;
}

.home-img2 {
  margin-right: 0;
}
.home-banner {
    padding: 0 20px;
}

.carousel-item {
    height: auto !important;
    padding-bottom: 50px;
}

.sem-title h2 {
    /* text-align: center; */
    font-size: 30px;
}

.sem-title {
    padding: 50px 20px;
}

.footer-bg {
    padding: 50px 20px;
}

.copy-right {
  padding-top: 50px;
  padding-bottom: 0;
}
.how-it-bg .outlineText1 {
  font-size: 245px;
}

.how-it-bg .outlineText1Drk {
  font-size: 241px;
}
.colsaps-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 72px;
  flex-direction: column;
  padding-bottom: 17px;
}
.menu-phone {
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  flex-direction: row-reverse;
}
.man-logo {
  position: absolute;
  width: 84px;
  left: 15px;
  top: -4px;
}
.menu-phone .ml-20 {
  position: absolute;
  left: 43px;
}
.colsaps-nav a {
  margin-left: 23px !important;
  margin-bottom: 7px;
}
.whoever-tr {
  position: absolute;
  z-index: 9;
  left: 17px;
  right: 0;
}
.how-it-bg .mr-48 {
  margin-bottom: 70px;
}
.mat-home {
  
  font-size: 20px;
  margin-top: 0;
}
.asked-box {
  margin-top: 30px;
}
.setup-bysetup {
  padding-bottom: 100px;
}
/******credits page**********/
.credits-page {
  padding: 0 60px;
}

.shear-card {
  padding: 35px 20px;
  text-align: center;
}

.shear-card .flex.flex-row {
  justify-content: center;
}

.credit-h2 {
  font-size: 35px;
}

.credit-h3 {
  font-size: 20px;
  line-height: inherit;
  
}
/*******partners**********/
.brandes-section {
  padding: 0 30px;
}

.increase-section .outlineText {
  font-size: 60px;
  line-height: normal;
}

.blueBg1 {
  padding: 30px 30px;
}

.increase-section .flex.flex-row.w-full {
  align-items: center;
}

.blueBg2 {
  padding: 30px 30px;
}

.blueBg3 {
  padding: 30px 30px;
}

.in-form-left {
  padding: 0;
  padding-right: 60px;
}

.inimg-2 img {
  width: 100%;
  height: 100%;
  margin-left: auto;
}

.in-form-left .partnerHeader {
  font-size: 21px;
  line-height: inherit;
}

.in-form-left .h3Light {
  width: 100%;
  margin-top: 30px;
  font-size: 20px;
}

.form-row {
  padding: 50px 30px;
  position: relative;
}

.inimg-2 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  width: 40%;
}
.brandes-section h2 {
  font-size: 28px;
  line-height: inherit;
  width: 93%;
}

.brandes-section .h3Light {
  margin-top: 30px;
  font-size: 18px;
  line-height: inherit;
  font-weight: 500;
  width: 97%;
  margin-bottom: 0;
}

.brandes-img {
  top: 0;
  left: 0;
  right: 0;
}
.brandes-img img {
  width: 280px;
  margin: 0 auto;
}

/* .brandes-img img {
  border-radius: 0;
} */

.why-text {
  text-align: left;
  margin-left: 30px;
  font-size: 18px;
}

.increase-section .partnerHeader {
  font-size: 23px;
  line-height: inherit;
}

.increase-section .h3Bold {
  font-size: 17px;
  line-height: inherit;
  width: 100%;
  opacity: 1;
}

.line-increase {
  display: none;
}
.in-form-left .uniqueBox {
  width: 280px;
}

.in-form-left .uniqueBoxDesc {
  width: 280px;
  height: 150px;
}

/*********type**********/
.type-page-pad {
  padding-top: 80px;
}
.video-wd {
  width: 100% !important;
  height: 400px !important;
}
.need-5-title {
  padding: 0 40px;
}
.type-page {
  padding: 50px 40px;
}
.get-title {
  padding: 0 40px;
}
.get-inspired {
  padding: 0 40px;
  margin-top: 50px;
}

.get-title h2 {
  font-size: 35px;
  line-height: inherit;
  margin-bottom: 20px;
}
.get-title .h3Light {
  font-size: 20px;
  line-height: inherit;
}
.get-inspired  .h3Dark24 {
  font-size: 18px;
}
.express-bg h2 {
  font-size: 25px;
}
.express-bg .h3Dark {
  font-size: 16px;
  line-height: inherit;
  font-weight: 400;
}
.express-bg {
  padding: 0 21px;
}
.express-box .itemBox .h3Dark24 {
  font-size: 18px;
  margin-top: 15px;
}
.express-box .h3Dark24 {
  font-size: 18px;
  margin-top: 15px;
}
.express-box .bg-white {
  height: auto;
}
.express-box .darkTitleBg {
  height: auto;
}
.need-5-title .h3Light {
  width: 100%;
  font-size: 20px;
  line-height: inherit;
}
.need-some-inspiration .h3Dark24 {
  font-size: 18px;
}
.video-twxt {
  width: 70%;
}
.need-video-row .h3Sub {
  text-align: center;
  font-size: 13px;
  line-height: inherit;
}
.video-twxt .h3Dark24 {
  font-size: 19px;
  line-height: inherit;
  margin-bottom: 10px;
}
.need-5-title h2 {
  font-size: 35px;
}
/******contact**********/
.contact-row {
  display: flex;
  flex-direction: column-reverse;
}

.contact-page {
  padding: 50px 20px;
}

.sub-mith-button {
  width: 100px;
  margin-right: 13px;
}

.form-button {
  text-align: right;
}
.contact-bg {
  margin-top: 0;
  padding-bottom: 30px;
}
/*********profile***********/
.profile-top {
  flex-direction: column-reverse;
  margin-top: 10px;
}

.profile-row {
  padding: 0 56px;
  position: relative;
  margin-top: 30px;
}
.profile-conn-box .uniqueBox {
  width: 100%;
}

.profile-top .shareBox {
  width: 100%;
}

.profile-conn-box h2 {
  font-size: 31px;
  line-height: inherit;
}

.profile-creatits {
  padding: 0 30px;
  margin-top: 80px;
}

.profile-row .imgBx {
  width: 250px;
  height: 287px;
}

.coin-box-align {
  margin-top: 50px;
  position: absolute;
  left: 67px;
}
/********profile*******/
.destop-block {
  display: none;
}
.ercd-phone .items-start {
  display: block;
  text-align: center;
  font-size: 35px;
  margin-top: 50px;
}
.profile-creatits h2 {
  font-size: 30px;
}
.upload-creadits-row .uploadSketch {
  top: 27%;
}
/******serch*******/
.serch-right a {
  width: 48%;
  height: auto;
  padding: 15px 10px;
}

.serch-right .gap-5 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.computer-bx {
  padding: 0 10px;
}

.serch-box-day {
  padding: 0 10px;
}

.card-bar {
  margin: 0 10px;
}

.products-cars-serch {
  margin: 0;
  height: auto;
}

.search-title h2 {
  font-size: 20px;
  width: 56%;
}

.search-title {
  align-items: center;
}

.serch-page {
  padding-top: 60px;
  margin: 0 20px;
}
/***********about**************/
.about-section {
  padding: 0 30px;
}

.what-we-stand-row {
  padding: 0 20px;
}

.johin-row {
  padding: 0 20px;
}

.about-product {
  padding-left: 30px;
  padding-right: 0;
}


.about-line-top img {
  width: 67%;
}
.about-line-dot img {
  width: 55%;
  margin-left: auto;
}

.about-line-dot {
  top: 328px;
  right: 0;
}
.about-bg-p h2 {
  padding-left: 53px;
  font-size: 30px;
  margin-bottom: 50px;
}

.about-bg-p {
  padding-top: 50px;
}
.about-stand {
  width: 60%;
}
.johin-row .genLight {
  line-height: inherit;
  font-size: 15px;
  padding: 12px 0;
  padding-top: 0;
}

.johin-row .h3Bold {
  line-height: initial;
  margin: 17px 0px;
  font-size: 18px;
}

.johin-row .orangeBg {
  padding: 13px 3px;
  font-size: 15px !important;
}

.johin-row .aboutgrad {
  padding: 40px 13px;
  background-size: cover;
}
.mack-book-card-1 .picBg,.mack-book-card-1 .picDrkBg {
  width: auto;
  height: auto;
}

.single-page {
  padding: 50px 20px;
}

.mack-book-card-1 {
  margin-right: 30px;
}

.mack-book-card-2 h2 {
  font-size: 29px;
  line-height: inherit;
}
.privacy-page h2 {
  font-size: 30px;
}

.privacy-page p {
  font-size: 18px;
}

.privacy-page {
  padding-bottom: 0;
}


.upcoming-row a {
  width: 100%;
  margin-bottom: 23px;
  height: auto;
}
.upcoming-row a .thumbImg {
  width: auto;
}
.about-logo .about-row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  padding: 0;
}
.about-phone-hide {
  display: none;
}
.upcoming-row {
  flex-wrap: wrap;
  display: flex;
}
.upcoming-row a {
  height: inherit;
  width: 46%;
  height: auto;
  padding-bottom: 15px;
}
.upcoming-row a .thumbImg {
  width: auto;
  padding: 10px 0px;
}
.computer-bx2 {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}
.computer-bx3 {
  padding: 0;
}
.computer-bx3 h3 {
  font-size: 18px;
}
.computer-bx3 .gen {
  font-size: 12px;
  line-height: inherit;
}
.computer-bx4 .gen {
  font-size: 12px;
  line-height: inherit;
  padding: 0;
}
.upcoming-row a .thumbImg img {
  width: 76px;
}
}

/* @media screen and (min-device-width: 992px) and (max-device-width: 1024px) { 
  .colsaps-nav {
    position: relative;
    top: 0;
    right: unset;
    flex-direction: inherit;
    padding: unset;
    left: 180px;
}
.menu-button {
  display: none;
}
.menu-phone .hidden {
  display: flex;
  margin-top: 14px;
}
.colsaps-nav a {
  margin-left: 15px !important;
  margin-bottom: 0;
}


} */

@media (min-width: 1025px) {
  .show-menu-bar {
    display: block;
}
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) { 
  .about-logo .about-row {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    padding: 0;
  }
  .about-phone-hide {
    display: none;
  }
  .colsaps-nav .menuItem {
    margin-left: 10px !important;
    font-size: 12px;
}
  .renters-gallray {
    flex-wrap: wrap;
    justify-content: center;
}

.explore-bg {
    flex-wrap: wrap;
    justify-content: center;
}

.sectWidth {
    width: auto;
}

.how-it-bg {
    flex-wrap: wrap;
}



.footer-bg {
    padding: 50px 50px;
}

.searchBxHome {
    width: auto;
}

.home-banner {
    padding: 0 50px;
}

.sem-title {
    padding: 50px 50px;
}
.outlineText1 {
  font-size: 200px;
}
.carousel-item {
  height: auto !important;
  padding-bottom: 50px;
}

.menu-phone {
  padding: 0 30px;
}

.sem-title h2 {
  font-size: 35px;
}
.menu-button {
  display: none;
}
.how-it-bg .mr-48 {
  margin-bottom: 53px;
}
/******credits page**********/
.credits-page {
  padding: 0 60px;
}

.shear-card {
  padding: 35px 20px;
  text-align: center;
}

.shear-card .flex.flex-row {
  justify-content: center;
}

.credit-h2 {
  font-size: 35px;
}

.credit-h3 {
  font-size: 20px;
  line-height: inherit;
  font-weight: 500;
}

/*******partners**********/
.brandes-section {
  padding: 0 30px;
}

.increase-section .outlineText {
  font-size: 60px;
  line-height: normal;
}

.blueBg1 {
  padding: 30 30px;
}

.increase-section .flex.flex-row.w-full {
  align-items: center;
}

.blueBg2 {
  padding: 30 30px;
}

.blueBg3 {
  padding: 30 30px;
}

.in-form-left {
  padding: 0;
  padding-right: 60px;
}

.inimg-2 img {
  width: 100%;
  height: 100%;
  margin-left: auto;
}

.in-form-left .partnerHeader {
  font-size: 21px;
  line-height: inherit;
}

.in-form-left .h3Light {
  width: 100%;
  margin-top: 30px;
  font-size: 20px;
}

.form-row {
  padding: 50px 30px;
  position: relative;
}

.inimg-2 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  width: 40%;
}
.brandes-section h2 {
  font-size: 28px;
  line-height: inherit;
  width: 93%;
}

.brandes-section .h3Light {
  margin-top: 30px;
  font-size: 18px;
  line-height: inherit;
  font-weight: 500;
  width: 97%;
  margin-bottom: 0;
}

.brandes-img {
  top: 0;
}

.brandes-img img {
  width: 60%;
  margin: 0 auto;
}
.why-text {
  text-align: left;
  margin-left: 30px;
  font-size: 18px;
}

.increase-section .partnerHeader {
  font-size: 23px;
  line-height: inherit;
}

.increase-section .h3Bold {
  font-size: 17px;
  line-height: inherit;
  width: 100%;
  opacity: 1;
}

.line-increase {
  display: none;
}
.in-form-left .uniqueBox {
  width: 280px;
}

.in-form-left .uniqueBoxDesc {
  width: 280px;
  height: 150px;
}
/*********type**********/
.video-wd {
  width: 100% !important;
  height: 400px !important;
}
.need-5-title {
  padding: 0 40px;
}
.type-page {
  padding: 50px 40px;
}
.get-title {
  padding: 0 40px;
}
.get-inspired {
  padding: 0 40px;
}
.get-title h2 {
  font-size: 35px;
  line-height: inherit;
  margin-bottom: 20px;
}
.get-title .h3Light {
  font-weight: 500;
  font-size: 20px;
  line-height: inherit;
}
.get-inspired  .h3Dark24 {
  font-size: 18px;
}
.express-bg h2 {
  font-size: 25px;
}
.express-bg .h3Dark {
  font-size: 16px;
  line-height: inherit;
  font-weight: 400;
}
.express-bg {
  padding: 0 21px;
}
.express-box .itemBox .h3Dark24 {
  font-size: 18px;
  margin-top: 15px;
}
.express-box .h3Dark24 {
  font-size: 18px;
  margin-top: 15px;
}
.express-box .bg-white {
  height: auto;
}
.express-box .darkTitleBg {
  height: auto;
}
.need-5-title .h3Light {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: inherit;
}
.need-some-inspiration .h3Dark24 {
  font-size: 18px;
}
.video-twxt {
  width: 70%;
}
.need-video-row .h3Sub {
  text-align: center;
  font-size: 13px;
  line-height: inherit;
}
.video-twxt .h3Dark24 {
  font-size: 19px;
  line-height: inherit;
  margin-bottom: 10px;
}
.need-5-title h2 {
  font-size: 35px;
}
/**********profile**********/
.profile-row .coinBox {
  width: 100%;
}

.profile-row {
  padding: 0 40px;
}

.profile-top .uniqueBox,.profile-top .uniqueBoxDrk {
  width: 100%;
}

.profile-conn-box .shareBox {
  width: 100%;
}

.profile-creatits {
  padding: 0 40px;
}

.bulak-row {
  display: flex;
  justify-content: space-between;
}

.bulak-row .sendBtn,.bulak-row .sendBtnDrk {
  width: auto;
  margin: 0;
  padding: 0 9px;
}

.profile-conn-box h2 {
  font-size: 35px;
}

/******serch*******/



.computer-bx {
  padding: 0 10px;
}

.serch-box-day {
  padding: 0 10px;
}

.card-bar {
  margin: 0 10px;
}

.products-cars-serch {
  margin: 0;
  height: 60.666667%;
}

.search-title h2 {
  font-size: 30px;
  width: 51%;
}
.search-title {
  align-items: center;
}

.serch-page {
  padding-top: 60px;
  margin: 0 20px;
}

/***********about**************/
.about-section {
  padding: 0 30px;
}

.what-we-stand-row {
  padding: 0 20px;
}

.johin-row {
  padding: 0 20px;
}

.about-product {
  padding-left: 30px;
  padding-right: 0;
}


.about-line-top img {
  width: 67%;
}
.about-line-dot img {
  width: 62%;
  margin-left: auto;
}

.about-line-dot {
  top: 205px;
  right: 0;
}
.about-bg-p h2 {
  padding-left: 53px;
  font-size: 30px;
  margin-bottom: 50px;
}

.about-bg-p {
  padding-top: 50px;
}
.about-line-dot img {
    width: 62%;
    margin-left: auto;
}
.mack-book-card-1 .picBg {
  width: auto;
  height: auto;
}

.single-page {
  padding: 50px 20px;
}

.mack-book-card-1 {
  margin-right: 30px;
}

.mack-book-card-2 h2 {
  font-size: 29px;
  line-height: inherit;
}
.upcoming-row .thumbImgBx, .upcoming-row .thumbImgBxDrk {
  width: auto;
  height: auto;
}
.profile-img9 {
  width: auto;
  height: auto;
}
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1365px) { 
  .colsaps-nav .menuItem {
    margin-left: 10px !important;
   
}
  .sectWidth {
    width: auto;
}

.searchBxHome {
    width: auto;
}

.renters-gallray {
    padding: 0 15px;
}

.sem-title {
    padding: 50px 30px;
}

.footer-bg {
    padding: 50px 24px;
}

.menu-button {
    display: none;
}

.menu-phone {
    padding: 0 50px;
}

.home-banner {
    padding: 0 50px;
}

.how-it-bg .outlineText1 {
    font-size: 130px;
}

.how-it-bg  .outlineText1Drk {
    font-size: 130px;
}
/******credits page**********/
.credits-page {
  padding: 0 60px;
}

.shear-card {
  padding: 35px 20px;
  text-align: center;
}

.shear-card .flex.flex-row {
  justify-content: center;
}

.credit-h2 {
  font-size: 35px;
}

.credit-h3 {
  font-size: 20px;
  line-height: inherit;
  font-weight: 500;
}
/*******partners********/
.brandes-section {
  padding: 0 40px;
}

.in-form-left {
  padding-left: 50px;
  padding-right: 100px;
  padding-top: 63px;
}

.inimg-2 img {
  width: 100%;
}

.form-row .w-4\/12 {
  width: 60%;
}

.in-form-left .partnerHeader {
  font-size: 25px;
}

.in-form-left .h3Light {
  width: 100%;
  margin-top: 20px;
}

.in-form-left .uniqueBoxDesc {
  height: 150px;
}
.partners-page2 {
  overflow: hidden;
}
.increase-section .blueBg1 {
  padding: 0px 40px;
}

.increase-section .blueBg2 {
  padding: 0px 40px;
}

.increase-section .blueBg3 {
  padding: 0px 40px;
}

.brandes-section h2 {
  font-size: 33px;
  line-height: inherit;
}

.brandes-img img {
  
  width: 91%;
  margin-left: auto;
}

.why-text {
  margin-left: 40px;
}
/*********type**********/
.video-wd {
  width: 100% !important;
  height: 400px !important;
}
.need-5-title {
  padding: 0 40px;
}
.type-page {
  padding: 50px 40px;
}
.get-title {
  padding: 0 40px;
}
.get-inspired {
  padding: 0 40px;
}
.get-title h2 {
  font-size: 35px;
  line-height: inherit;
  margin-bottom: 20px;
}
.get-title .h3Light {
  font-weight: 500;
  font-size: 20px;
  line-height: inherit;
}
.get-inspired  .h3Dark24 {
  font-size: 18px;
}
.express-bg h2 {
  font-size: 25px;
}
.express-bg .h3Dark {
  font-size: 16px;
  line-height: inherit;
  font-weight: 400;
}
.express-bg {
  padding: 0 21px;
}
.express-box .itemBox .h3Dark24 {
  font-size: 18px;
  margin-top: 15px;
}
.express-box .h3Dark24 {
  font-size: 18px;
  margin-top: 15px;
}
.express-box .bg-white {
  height: auto;
}
.express-box .darkTitleBg {
  height: auto;
}
.need-5-title .h3Light {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: inherit;
}
.need-some-inspiration .h3Dark24 {
  font-size: 18px;
}
.video-twxt {
  width: 70%;
}
.need-video-row .h3Sub {
  text-align: center;
  font-size: 13px;
  line-height: inherit;
}
.video-twxt .h3Dark24 {
  font-size: 19px;
  line-height: inherit;
  margin-bottom: 10px;
}
.need-5-title h2 {
  font-size: 35px;
}
.profile-row {
  padding: 0 40px;
}

.profile-creatits {
  padding: 0 40px;
}
.products-cars-serch {
  margin: 0;
 
}
.search-title h2 {
  font-size: 35px;
}

.search-title {
  align-items: center;
}
/***********about**************/
.about-section {
  padding: 0 50px;
}
.what-we-stand-row {
  padding: 0 50px;
}

.johin-row {
  padding: 0 20px;
}

.about-product {
  padding-left: 30px;
  padding-right: 0;
}


.about-line-top img {
  width: 82%;
}
.about-line-dot img {
  width: 100%;
  margin-left: auto;
}

.about-line-dot {
  top: 114px;
  right: 0;
}
.about-bg-p h2 {
  padding-left: 53px;
  margin-bottom: 50px;
}

.about-bg-p {
  padding-top: 50px;
}

.single-page {
  padding: 50px 25px;
}

.upcoming-row .thumbImgBx, .upcoming-row .thumbImgBxDrk {
  width: auto;
  height: auto;
}

}


@media screen and (min-device-width: 1366px) and (max-device-width: 1900px) { 
  .inimg-2 img {
    width: 100%;
}

.about-line-dot {
  right: -83px;
}
.in-form-left {
    padding-left: 70px;
}



.form-row .w-4\/12 {
    width: 50%;
}

.uniqueBoxDesc {
    height: 150px;
}

.in-form-left .h3Light {
    width: 90%;
    font-weight: 500;
}
.products-cars-serch {
  margin: 0;
 
}
}

.Selectable .DayPicker-Day--end.DayPicker-Day--outside{
  background-color: transparent!important;
}


.Selectable .DayPicker-Day--start.DayPicker-Day--outside{
  background-color: transparent!important;
}
.shear-book-title {
  font-size: 30px;
  line-height: inherit;
}